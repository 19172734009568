<template>
    <link href="https://fonts.googleapis.com/css?family=Pacifico&display=swap" rel="stylesheet">

    <!-- <div class="banner">
      Daria i David Z RADOŚCIĄ ZAWIADAMIAJĄ, ŻE... POBIERAMY SIĘ!
    </div> -->

  <router-view/>
</template>

<!-- TODO add icon as logo -->
<!-- TODO check all screen sizes; maybe 4k? -->

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico&display=swap');
@import url('https://fonts.google.com/specimen/Pacifico?query=Pacifico+');
@import url('https://fonts.google.com/specimen/Josefin+Sans?query=Josefin+Sans+ ');
@import url('https://fonts.google.com/specimen/Great+Vibes?query=Great+Vibes');
@import url('https://fonts.google.com/specimen/Playfair+Display?query=Playfair+Displa');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');
@import 'animate.css';
</style>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-text-color);
  background-color: #fff;
  min-height: 100vh;
}

.mobile--only {
    display: none;
}

body {
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
}

.no-scroll {
    overflow-y: hidden;
    position: fixed;
    height: 100vh;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.banner {
  font-family: 'Roboto Serif', serif;
  padding: 1em 0;
  font-size: 2.75em;
  text-align: center;
  width: 100%;
  color: white;
  background-color: lightpink;
  display: flex;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, 
    #ffffff 0%, 
    #ffb6c1 9%, 
    #ffc0cb 17%, 
    #FE7F9C 51%, /* dark pink */
    #ffc0cb 82%,
    #ffb6c1 91%,
    #ffffff 100%
    )

    /* dark pink */
    /* rose FC94AF */
}

.ribbon {
  width: 400px;
  height: 60px;
  margin: 100px auto 0px;
  position: relative;
  color: #fff;
  font: 28px/60px sans-serif;
  text-align: center;
  text-transform: uppercase;
  background: #3D79D0;
  
  -webkit-animation: main 250ms;
  -moz-animation: main 250ms;
  -ms-animation: main 250ms;
  animation: main 250ms;
}

.ribbon i {
  position: absolute;
}

.ribbon i:first-child, .ribbon i:nth-child(2) {
  position: absolute;
  left: -20px;
  bottom: -20px;
  z-index: -1;
  border: 20px solid transparent;
  border-right-color: #043140;
  
  -webkit-animation: edge 500ms;
  -moz-animation: edge 500ms;
  -ms-animation: edge 500ms;
  animation: edge 500ms;
}

.ribbon i:nth-child(2) {
  left: auto;
  right: -20px;
  border-right-color: transparent;
  border-left-color: #043140;
}

.ribbon i:nth-child(3), .ribbon i:last-child {
  width: 20px;
  bottom: -20px;
  left: -60px;
  z-index: -2;
  border: 30px solid #1D53C0;
  border-left-color: transparent;
  
  -webkit-animation: back 600ms;
  -moz-animation: back 600ms;
  -ms-animation: back 600ms;
  animation: back 600ms;
  
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.ribbon i:last-child {
  bottom: -20px;
  left: auto;
  right: -60px;
  border: 30px solid #1D53C0;
  border-right-color: transparent;

  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

p {
  text-align: center;
  color: #aaa;
}

/* animations */

@-webkit-keyframes main {
  0% { -webkit-transform: scaleX(0); }
  100% { -webkit-transform: scaleX(1); }
}

@-webkit-keyframes edge {
  0%, 50% { -webkit-transform: scaleY(0); }
  100% { -webkit-transform: scaleY(1); }
}

@-webkit-keyframes back {
  0%, 75% { -webkit-transform: scaleX(0); }
  100% { -webkit-transform: scaleX(1); }
}


@-moz-keyframes main {
  0% { -moz-transform: scaleX(0); }
  100% { -moz-transform: scaleX(1); }
}

@-moz-keyframes edge {
  0%, 50% { -moz-transform: scaleY(0); }
  100% { -moz-transform: scaleY(1); }
}

@-moz-keyframes back {
  0%, 75% { -moz-transform: scaleX(0); }
  100% { -moz-transform: scaleX(1); }
}


@keyframes main {
  0% { transform: scaleX(0); }
  100% { transform: scaleX(1); }
}

@keyframes edge {
  0%, 50% { transform: scaleY(0); }
  100% { transform: scaleY(1); }
}

@keyframes back {
  0%, 75% { transform: scaleX(0); }
  100% { transform: scaleX(1); }
}

@media screen and (max-width: 768px) {
  .mobile--none {
    display: none !important;
  }

  .mobile--text-center {
    text-align: center;
  }
}
</style>
