
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue'
import ribbonDivider from '../utils/ribbon-divider.vue';

const __default__ = defineComponent({
  components: { ribbonDivider },
    props: {
        sectionHeader: {
            type: String,
            required: true
        },
        sectionTitle: {
            type: String,
            required: true
        },
        sectionAddress: {
            type: String,
            required: true
        },
        sectionTime: {
            type: String,
            required: true
        },
        sectionDate: {
            type: String,
            required: false
        },
        sectionImg: {
            type: String,
            required: true
        },
        gMap: {
            type: String,
            required: true
        }
    },
    setup (props) {
        let mapModal = ref(false);

        const openMapModal = () => {
            document.body.classList.toggle('no-scroll');
            const scrollDiv = document.getElementById(props.sectionHeader + '-map');
            const offsetTop = scrollDiv?.getBoundingClientRect().top;

            const setoffset = () => {
                document.body.style.top = `-${offsetTop}px`;
            };

            const removeOffset = () => {
                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            };

            mapModal.value ? removeOffset() : setoffset();
            mapModal.value = !mapModal.value;
        };

        const isElementInViewport = (el: HTMLElement) => {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight + 100 || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        const backgorundImg = computed(() => {
            return `url(${props.sectionImg})`
        });

        onMounted(() => {
            nextTick(() => {
                const elementsToAnimate = document.querySelectorAll('.animate__animated');

                elementsToAnimate.forEach(element => {
                    window.addEventListener('scroll', () => {
                        if (isElementInViewport(element as HTMLElement)) {
                            element.classList.add('animate__fadeInDown');
                        }
                    })
                })
            })
        })

        return {
            props,
            mapModal,
            openMapModal,
            backgorundImg
        }
    }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "38d1f740": (_ctx.backgorundImg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__