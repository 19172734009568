
import { defineComponent } from 'vue'
import ribbonDivider from '../utils/ribbon-divider.vue'
import { ref } from 'vue'

export default defineComponent({
  components: { ribbonDivider },
    setup () {
        let mapModal = ref(false);

        const openMapModal = () => {
            mapModal.value = !mapModal.value;
        }

        return {
            mapModal,
            openMapModal
        }
    }
})
