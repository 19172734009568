
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        imgUrl: {
            type: String,
            required: true
        },
        imgHeader: {
            type: String,
            required: true
        },
        imgText: {
            type: String,
            required: true
        },
        imgNumber: {
            type: String,
            required: true
        }
    },
    setup (props) {
        

        return {
            props
        }
    }
})
