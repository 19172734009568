
import imageCard from '@/components/card/image-card.vue'
import ContentSection from '@/components/content/content-section.vue'
import primaryNav from '@/components/nav/primary-nav.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { imageCard, ContentSection, primaryNav },
    setup () {

        const imageData = [
            {
                imgUrl: require('../assets/images/dd1.jpg'),
                imgHeader: '8 MAJ 2016',
                imgText: 'POZNALIŚMY SIĘ!',
                imgNumber: '01.',
            },
            {
                imgUrl: require('../assets/images/dd2.jpg'),
                imgHeader: '2 LIP 2021',
                imgText: 'POWIEDZIAŁA "TAK"!',
                imgNumber: '02.',
            },
            {
                imgUrl: require('../assets/images/dd3.jpg'),
                imgHeader: '20 MAJ 2023',
                imgText: 'I ŻYLI DŁUGO I SZCZĘŚLIWIE...',
                imgNumber: '03.'
            }
        ];

        const contentSections = [
            {
                header: 'Zaślubiny',
                title: 'Kościół p.w. Nawiedzenia Najświętszej Maryi Panny',
                address: '57-100, Biały Kościół',
                time: '15:00',
                date: '20.05.2023',
                imgUrl: require('../assets/white_church.png'),
                gMap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5050.842517086918!2d17.04068135!3d50.73067525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e2b7479f09ced%3A0x16a78789449e371d!2zNTctMTAwIEJpYcWCeSBLb8WbY2nDs8WC!5e0!3m2!1sen!2spl!4v1677944730292!5m2!1sen!2spl'
            },
            {
                header: 'Wesele',
                title: 'Hacjenda Dolina Żab',
                address: 'Dębniki 38, 57-100 Dębniki',
                time: 'Po uroczystości zaślubin',
                date: '20.05.2023',
                imgUrl: require('../assets/images/dd_hacjenda_dolina_zab.jpg'),
                gMap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2524.987184056868!2d17.070127415476293!3d50.73872657443234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e2b1f3d44e0b1%3A0xa6dd687ce59c51a9!2sHacjenda%20Dolina%20%C5%BBab!5e0!3m2!1sen!2spl!4v1678490100187!5m2!1sen!2spl'
            },
            {
                header: 'Transport',
                title: '',
                address: 'Wrocław, parking ul. Slężna - na przeciwko Uniwersytetu Ekonomicznego (przy wzgórzu Andersa)',
                time: '13:30',
                // date: '',
                imgUrl: require('../assets/images/dd-transport.jpg'),
                gMap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5050.842517086918!2d17.04068135!3d50.73067525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e2b7479f09ced%3A0x16a78789449e371d!2zNTctMTAwIEJpYcWCeSBLb8WbY2nDs8WC!5e0!3m2!1sen!2spl!4v1677944730292!5m2!1sen!2spl'
            },
            {
                header: 'Nocleg',
                title: 'Ośrodek wypoczynkowy "Nad stawami"',
                address: ' ul. Nad Stawami, 57-100, Biały Kościół',
                time: 'Doba hotelowa zaczyna się od 15:00 a kończy o 12:00',
                date: '19-21.05.2023',
                imgUrl: require('../assets/images/dd-nocleg.jpg'),
                gMap: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.692567339218!2d17.044013015475834!3d50.725642475378876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e2b6b71a32a83%3A0x7409988bcdfde7ad!2sO%C5%9Brodek%20Wypoczynkowy%20Nad%20Stawami%20w%20Bia%C5%82ym%20Ko%C5%9Bciele!5e0!3m2!1sen!2spl!4v1678489002937!5m2!1sen!2spl'
            }
        ]

        return {
            imageData,
            contentSections
        }
    }
})
