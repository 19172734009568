
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {

    const scrollToDiv = (div: string) => {
      const el = document.getElementById(div);
      const topOffset = 100;
      if (!el) return;
      const topPos = el.getBoundingClientRect().top + window.pageYOffset - topOffset;

      window.scrollTo({
        top: topPos,
        behavior: 'smooth'
      });
    }

    return {
      scrollToDiv
    }
  }
})
